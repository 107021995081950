<template>
  <div class="services-page">
    <b-row class="m-0 pt-1">
      <b-col class="pr-0">
        <div class="d-flex w-100">
          <div class="title py-2 pr-2 my-auto">Services</div>
          <div ref="navToggle" class="nav-toggle d-flex my-auto ml-auto" @click="toggleNavMobile">
            <fa-icon class="toggle-button m-auto" icon="ellipsis-v" size="lg" />
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="auto">
        <div class="d-flex w-100 h-100 py-2">
          <b-button
            class="primary-button ml-auto my-auto"
            variant="primary"
            to="/hire-us"
            :disabled="!hasCompanies || loading"
            aria-label="Add Services Button"
          >
            + Add Services
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr class="m-0">
    <div v-if="!loading" class="content-body">
      <div class="side-nav" :class="navClass">
        <div class="nav-button" :class="tabClass(0)" @click="selectedTab = 0">
          <div class="d-flex flex-column align-items-center text-center m-auto pa-2">
            <fa-icon class="mx-auto mt-1" icon="home" size="lg" />
            <span class="mt-1">My Services</span>
          </div>
        </div>
        <div class="nav-button" :class="tabClass(1)" @click="selectedTab = 1">
          <div class="d-flex flex-column align-items-center text-center m-auto pa-2">
            <fa-icon class="mx-auto mt-1" icon="handshake" size="lg" />
            <div class="mt-1">Shared</div>
            <div>With Me</div>
          </div>
        </div>
      </div>
      <div class="services-content">
        <div class="d-flex flex-column flex-md-row">
          <div v-if="showCompanyFilters" class="search-container d-flex">
            <b-input-group>
              <b-form-input
                v-model="searchFilter"
                type="text"
                placeholder="Search"
              />
              <b-input-group-text>
                <feather-icon type="search" />
              </b-input-group-text>
            </b-input-group>
          </div>
          <div v-if="selectedTab === 0" class="ml-md-auto pl-md-2 d-flex">
            <b-button
              class="primary-button-outlined mr-1"
              variant="primary-outline"
              aria-label="Manage Autopay Button"
              :disabled="manageAutopayDisabled"
              @click="manageAutopay(selectedIds)"
            >
              Manage Autopay
            </b-button>
            <b-button
              class="danger-button-outlined ml-1"
              variant="primary-outline"
              aria-label="Cancel Services Button"
              :disabled="cancelServicesDisabled"
              @click="cancelServices"
            >
              Cancel Services
            </b-button>
          </div>
        </div>
        <div v-if="showCompanyFilters" class="my-4 ml-2">
          <span class="hover-pointer" @click="sortCompanies">
            <b-icon-chevron-down v-if="sortedDescending" class="my-auto" scale="1.2" />
            <b-icon-chevron-up v-else class="my-auto" scale="1.2" />
            <span class="ml-2">Company Name</span>
          </span>
        </div>
        <div v-show="selectedTab === 0">
          <div v-for="(company, idx) in ownedCompanies" :key="company.id" class="mb-4">
            <company-services :ref="`${company.id}-services`" v-model="expandedCompanies[idx]" :company="company" :search-filter="searchFilter" />
          </div>
          <div v-if="!ownedCompanies.length" class="text-center">
            No Companies Found
          </div>
        </div>
        <div v-show="selectedTab === 1">
          <div v-for="(company, idx) in sharedCompanies" :key="company.id" class="mb-4">
            <company-services v-model="expandedShared[idx]" shared :company="company" :search-filter="searchFilter" />
          </div>
          <div v-if="!sharedCompanies.length" class="text-center">
            <h5>No Shared Companies Found</h5>
          </div>
        </div>
      </div>
    </div>
    <ct-centered-spinner v-else />
    <cancellation-modal
      :bus="bus"
      @cancel-complete="cancelServicesComplete"
    />
    <rick-roll-modal :bus="bus" />
  </div>
</template>

<script>

import { companyServiceMixin } from '@/mixins/companyServiceMixin'
import CancellationModal from '@/components/cancellation/CancellationModal'
import RickRollModal from '@/components/cancellation/RickRollModal'
import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import FeatherIcon from '@/components/shared/FeatherIcon.vue'
import CompanyServices from '@/components/Services/CompanyServices/CompanyServices.vue'
import { SET_SELECTED_SERVICES } from '@/store/mutations'
import http from '@/http'
import { CLIENT_COMPANIES_INDEX_SIMPLE, CLIENT_COMPANIES_SHARED_SIMPLE } from '@/api/v3/endpoints'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'Services',
  components: { CompanyServices, FeatherIcon, CtCenteredSpinner, CancellationModal, RickRollModal },
  mixins: [companyServiceMixin, makeToastMixin],
  data() {
    return {
      bus: new Vue(),
      expandedCompanies: [],
      expandedShared: [],
      loading: false,
      ownedCompanies: [],
      searchFilter: null,
      selectedTab: 0,
      sharedCompanies: [],
      showNavMobile: false,
      sortOwnedDescending: false,
      sortSharedDescending: false,
    }
  },
  computed: {
    ...mapGetters('account', ['isAccountWholesaler', 'activeAccountServices']),
    ...mapGetters('services', ['selectedServices']),
    cancelServicesDisabled() {
      return !this.selectedServices.length || this.loading
    },
    hasCompanies() {
      return this.selectedTab === 0 ? this.ownedCompanies.length > 0 : this.sharedCompanies.length > 0
    },
    manageAutopayDisabled() {
      return !this.hasCompanies || this.loading || !this.selectedAutoPayables.length
    },
    navClass() {
      return this.showNavMobile ? null : 'hidden'
    },
    selectedAutoPayables() {
      return this.selectedServices
        .filter(sel => this.autopayPayableSelectable(sel.status))
        .filter(this.isSelectable || this.isDomainService)
        .map(svc => svc.id)
    },
    showCompanyFilters() {
      return this.selectedTab === 0 && this.ownedCompanies.length > 0
        || this.selectedTab === 1 && this.sharedCompanies.length > 0
    },
    sortedDescending() {
      return this.selectedTab === 0 ? this.sortOwnedDescending : this.sortSharedDescending
    },
    selectedIds(){
      return this.selectedServices.map(svc => svc.id)
    },
  },
  async mounted() {
    this.loading = true
    this.setSelected([])
    await Promise.all([
      this.loadIndexSimple(),
      this.loadSharedSimple(),
    ])
    this.loading = false
  },
  methods: {
    ...mapMutations('services', { setSelected: SET_SELECTED_SERVICES }),
    async cancelServices() {
      this.bus.$emit('showCancelServices', this.selectedServices)
    },
    async cancelServicesComplete() {
      const selectedCompanyIds = [...new Set(this.selectedServices.map(svc => svc.company_id))]
      selectedCompanyIds.forEach(cid => this.$refs[`${cid}-services`][0].loadServices())
      this.setSelected([])
      this.bus.$emit('showRickRoll')
      this.successToast('Success', 'Service cancellation request succeeded')
    },
    async loadIndexSimple() {
      this.expandedCompanies = this.ownedCompanies.map(() => false)
      const { data } = await http.get(CLIENT_COMPANIES_INDEX_SIMPLE, { params: {
          order_by: 'name',
          order_direction: 'desc',
        } })

      if(!data?.success) {
        this.errorToast('Error', 'Error loading companies')
        return
      }
      this.ownedCompanies = data.result
      this.expandedCompanies = this.ownedCompanies.map(() => true)
    },
    async loadSharedSimple() {
      this.expandedShared = this.sharedCompanies.map(() => false)
      const { data } = await http.get(CLIENT_COMPANIES_SHARED_SIMPLE, { params: {
          order_by: 'name',
          order_direction: 'desc',
        } })

      if(!data?.success) {
        this.errorToast('Error', 'Error loading companies')
        return
      }
      this.sharedCompanies = data.result
      this.expandedShared = this.sharedCompanies.map(() => true)
    },
    async sortCompanies() {
      if(this.selectedTab === 0) {
        if(this.ownedCompanies.length === 0) return
        this.sortOwnedDescending = !this.sortOwnedDescending
        this.ownedCompanies = this.ownedCompanies.sort((a, b) =>  this.sortOwnedDescending ?  a.name.localeCompare(b.name) : b.name.localeCompare(a.name))
        this.expandedCompanies = this.ownedCompanies.map(() => true)
      } else {
        if(this.sharedCompanies.length === 0) return
        this.sortSharedDescending = !this.sortSharedDescending
        this.sharedCompanies.sort((a, b) => this.sortSharedDescending ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name))
        this.expandedShared = this.sharedCompanies.map(() => true)
      }
    },
    tabClass(number) {
      return number === this.selectedTab ? 'selected' : null
    },
    toggleNavMobile() {
      this.showNavMobile = !this.showNavMobile
    },
  },
}
</script>

<style lang="scss">
.services-page {
  min-width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: -20px -15px;
  display: flex;
  flex-direction: column;

  .hover-pointer {
    :hover {
      cursor: pointer;
    }
  }

  .nav-toggle {
    display: none;
    * {
      display: none;
    }
    @media only screen and (max-width: 767px) {
      display: flex;
      border: 1px solid #BDBDBD;
      border-radius: 8px 0 0 8px;
      width: 50px;
      height: 50px;
      * {
        display: block;
      }
    }
  }

  .primary-button {
    background-color: #000864;
    border-color: #000864 !important;
    color: white;
    border-radius: 4px;

    &:not(:disabled):hover {
      background-color: #3F458A !important;
    }

    &-outlined {
      color: #000864 !important;
      border-color: #000864 !important;
      border-radius: 4px !important;
      background-color: white !important;
      height: 40px;

      &:not(:disabled):hover {
        background-color: #ECECFA !important;
      }
    }
  }
  button {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .danger-button-outlined {
    color: #BC2F2F !important;
    border-color: #BC2F2F !important;
    border-radius: 4px !important;
    height: 40px;

    &:not(:disabled):hover {
      background-color: #FCF0F0 !important;
    }
  }

  .title {
    font-size: 2rem;
    font-weight: bold;
  }

  hr {
    border-top: 1px solid #BDBDBD;
    margin: 0;
  }

  .content-body {
    flex: 1;
    display: flex;

    .side-nav {
      background-color: #F8F8F8;
      min-height: 100%;
      min-width: 125px;
      width: 125px;
      margin-bottom: .25rem;

      .nav-button {
        display: flex;
        width: 100%;
        height: 100px;

        :hover {
          cursor: pointer;
        }

        &.selected {
          background-color: #EAE9FC
        }
      }

      @media (min-width: 768px) {
        * {
          display: flex !important;
        }
      }
    }

    .services-content {
      min-height: 100%;
      flex: 1;
      padding: 1rem;

      .search-container {
        min-width: 250px;
        .form-control {
          min-height: 40px !important;
          flex: 1;
          border-right: none;
        }

        .input-group-text {
          height: 40px !important;
          border-left: none;
          border-radius: 0 4px 4px 0;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .primary-button, .primary-button-outlined, .danger-button-outlined {
      flex-grow: 1;
      height: auto;
      padding-left: .25rem;
      padding-right: .25rem;
    }

    .content-body {
      flex-direction: column;

      .side-nav {
        min-height: 0;
        width: 100%;
        display: flex;
        flex-direction: row;

        .nav-button {
          flex: 1;
          height: 75px;
        }

        &.hidden {
          display: none;
          * {
            display: none;
          }
        }
      }
      .services-content {
        min-height: 100%;
        flex: 1;

        .search-container {
          .form-control {
            min-width: 0;
          }

          .input-group-text {
            min-width: 0;
          }
        }
      }
    }
  }
}
</style>
