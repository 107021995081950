var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "services-page" },
    [
      _c(
        "b-row",
        { staticClass: "m-0 pt-1" },
        [
          _c("b-col", { staticClass: "pr-0" }, [
            _c("div", { staticClass: "d-flex w-100" }, [
              _c("div", { staticClass: "title py-2 pr-2 my-auto" }, [
                _vm._v("Services"),
              ]),
              _c(
                "div",
                {
                  ref: "navToggle",
                  staticClass: "nav-toggle d-flex my-auto ml-auto",
                  on: { click: _vm.toggleNavMobile },
                },
                [
                  _c("fa-icon", {
                    staticClass: "toggle-button m-auto",
                    attrs: { icon: "ellipsis-v", size: "lg" },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("b-col", { attrs: { cols: "12", md: "auto" } }, [
            _c(
              "div",
              { staticClass: "d-flex w-100 h-100 py-2" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "primary-button ml-auto my-auto",
                    attrs: {
                      variant: "primary",
                      to: "/hire-us",
                      disabled: !_vm.hasCompanies || _vm.loading,
                      "aria-label": "Add Services Button",
                    },
                  },
                  [_vm._v("\n          + Add Services\n        ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("hr", { staticClass: "m-0" }),
      !_vm.loading
        ? _c("div", { staticClass: "content-body" }, [
            _c("div", { staticClass: "side-nav", class: _vm.navClass }, [
              _c(
                "div",
                {
                  staticClass: "nav-button",
                  class: _vm.tabClass(0),
                  on: {
                    click: function ($event) {
                      _vm.selectedTab = 0
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column align-items-center text-center m-auto pa-2",
                    },
                    [
                      _c("fa-icon", {
                        staticClass: "mx-auto mt-1",
                        attrs: { icon: "home", size: "lg" },
                      }),
                      _c("span", { staticClass: "mt-1" }, [
                        _vm._v("My Services"),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "nav-button",
                  class: _vm.tabClass(1),
                  on: {
                    click: function ($event) {
                      _vm.selectedTab = 1
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column align-items-center text-center m-auto pa-2",
                    },
                    [
                      _c("fa-icon", {
                        staticClass: "mx-auto mt-1",
                        attrs: { icon: "handshake", size: "lg" },
                      }),
                      _c("div", { staticClass: "mt-1" }, [_vm._v("Shared")]),
                      _c("div", [_vm._v("With Me")]),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "services-content" }, [
              _c("div", { staticClass: "d-flex flex-column flex-md-row" }, [
                _vm.showCompanyFilters
                  ? _c(
                      "div",
                      { staticClass: "search-container d-flex" },
                      [
                        _c(
                          "b-input-group",
                          [
                            _c("b-form-input", {
                              attrs: { type: "text", placeholder: "Search" },
                              model: {
                                value: _vm.searchFilter,
                                callback: function ($$v) {
                                  _vm.searchFilter = $$v
                                },
                                expression: "searchFilter",
                              },
                            }),
                            _c(
                              "b-input-group-text",
                              [
                                _c("feather-icon", {
                                  attrs: { type: "search" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedTab === 0
                  ? _c(
                      "div",
                      { staticClass: "ml-md-auto pl-md-2 d-flex" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "primary-button-outlined mr-1",
                            attrs: {
                              variant: "primary-outline",
                              "aria-label": "Manage Autopay Button",
                              disabled: _vm.manageAutopayDisabled,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.manageAutopay(_vm.selectedIds)
                              },
                            },
                          },
                          [_vm._v("\n            Manage Autopay\n          ")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "danger-button-outlined ml-1",
                            attrs: {
                              variant: "primary-outline",
                              "aria-label": "Cancel Services Button",
                              disabled: _vm.cancelServicesDisabled,
                            },
                            on: { click: _vm.cancelServices },
                          },
                          [_vm._v("\n            Cancel Services\n          ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm.showCompanyFilters
                ? _c("div", { staticClass: "my-4 ml-2" }, [
                    _c(
                      "span",
                      {
                        staticClass: "hover-pointer",
                        on: { click: _vm.sortCompanies },
                      },
                      [
                        _vm.sortedDescending
                          ? _c("b-icon-chevron-down", {
                              staticClass: "my-auto",
                              attrs: { scale: "1.2" },
                            })
                          : _c("b-icon-chevron-up", {
                              staticClass: "my-auto",
                              attrs: { scale: "1.2" },
                            }),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v("Company Name"),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selectedTab === 0,
                      expression: "selectedTab === 0",
                    },
                  ],
                },
                [
                  _vm._l(_vm.ownedCompanies, function (company, idx) {
                    return _c(
                      "div",
                      { key: company.id, staticClass: "mb-4" },
                      [
                        _c("company-services", {
                          ref: `${company.id}-services`,
                          refInFor: true,
                          attrs: {
                            company: company,
                            "search-filter": _vm.searchFilter,
                          },
                          model: {
                            value: _vm.expandedCompanies[idx],
                            callback: function ($$v) {
                              _vm.$set(_vm.expandedCompanies, idx, $$v)
                            },
                            expression: "expandedCompanies[idx]",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  !_vm.ownedCompanies.length
                    ? _c("div", { staticClass: "text-center" }, [
                        _vm._v("\n          No Companies Found\n        "),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.selectedTab === 1,
                      expression: "selectedTab === 1",
                    },
                  ],
                },
                [
                  _vm._l(_vm.sharedCompanies, function (company, idx) {
                    return _c(
                      "div",
                      { key: company.id, staticClass: "mb-4" },
                      [
                        _c("company-services", {
                          attrs: {
                            shared: "",
                            company: company,
                            "search-filter": _vm.searchFilter,
                          },
                          model: {
                            value: _vm.expandedShared[idx],
                            callback: function ($$v) {
                              _vm.$set(_vm.expandedShared, idx, $$v)
                            },
                            expression: "expandedShared[idx]",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  !_vm.sharedCompanies.length
                    ? _c("div", { staticClass: "text-center" }, [
                        _c("h5", [_vm._v("No Shared Companies Found")]),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ])
        : _c("ct-centered-spinner"),
      _c("cancellation-modal", {
        attrs: { bus: _vm.bus },
        on: { "cancel-complete": _vm.cancelServicesComplete },
      }),
      _c("rick-roll-modal", { attrs: { bus: _vm.bus } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }